import React from "react";
// components
import useBlobity from "blobity/lib/react/useBlobity";
import About from "./components/About";
import Banner from "./components/Banner";
import Contact from "./components/Contact";
import Header from "./components/Header";
import Nav from "./components/Nav";
import Services from "./components/Services";

const App = () => {
  const blobity = useBlobity({
    color: "rgb(80, 80, 80)",
    opacity: 0.3,
    mode: "bouncy",
    zIndex: 60,
    fontSize: 30,
    fontColor: "rgb(255, 255, 255)"
  });
  return (
    <div>
      <div className="bg-site bg-no-repeat bg-cover overflow-hidden -z-10 cursor-none">
        <Header />
        <Banner />
        <Nav />
        <About />
        <Services />
        <Contact />
      </div>
    </div>
  );
};

export default App;

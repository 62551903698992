import React from 'react';
import { BiHomeAlt, BiUser } from 'react-icons/bi';
import { BsChatSquare, BsClipboardData } from 'react-icons/bs';
import { Link } from 'react-scroll';


const Nav = () => {
  return <nav className='fixed bottom-2 lg:bottom-8 w-full overflow-hidden z-50'>
    <div className='container mx-auto'>
      <div className=' w-full bg-emerald-300/20 h-[96px] backdrop-blur-2xl rounded-full max-w-[460px] mx-auto px-10 flex justify-between items-center text-2xl text-black/50'>
        <Link to='home' activeClass='bg-black/10 rounded-full' spy={true} smooth={true} offset={-200}className='cursor-none w-[60px] h-[60px] flex items-center justify-center' data-blobity-radius="30">
          <BiHomeAlt />
        </Link>
        <Link to='about' activeClass='bg-black/10 rounded-full' spy={true} smooth={true} className='cursor-none w-[60px] h-[60px] flex items-center justify-center' data-blobity-radius="30">
          <BiUser />
        </Link>
        <Link to='services' activeClass='bg-black/10 rounded-full' spy={true} smooth={true} className='cursor-none w-[60px] h-[60px] flex items-center justify-center' data-blobity-radius="30">
          <BsClipboardData />
        </Link>
        <Link to='contact' activeClass='bg-black/10 rounded-full' spy={true} smooth={true} className='cursor-none w-[60px] h-[60px] flex items-center justify-center' data-blobity-radius="30">
          <BsChatSquare />
        </Link>
      </div>
    </div>
  </nav>;
};

export default Nav;

import React, { useEffect } from 'react'

const RedirectNotion = () => {
    useEffect(() => {
        window.location.href = "https://alex-yan.notion.site/alex-yan/c00032d9848a48f9a3bbd57a7a8d2baa"
    }, [])
  return (
    <></>
  )
}

export default RedirectNotion
import React from "react";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";
import { SiGmail, SiNotion } from "react-icons/si";
import { AiOutlineMail } from "react-icons/ai";

const Contact = () => {
  return (
    <div className="min-h-[85vh] lg:min-h-[78vh] flex items-center mt-24" id="contact">
      <div className="container mx-auto text-black h-screen">
        <motion.div
          variants={fadeIn("down", 0.3)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.3 }}
          className="flex-1 mt-10"
        >
          <h2 className="font-primary text-[48px] text-emerald-600">
            Contact Me
          </h2>
          <div className="relative hidden lg:block">
            <iframe
              src="https://notioniframe.com/notion/j1e7fvi4kdr"
              className="h-[70vh] w-[80%] shadow-xl border-emerald-600/40 border-4 mt-6"
            ></iframe>
            <a
              href="https://alex-yan.notion.site/alexyan-me-Message-Board-1fbbfb62cb014b02a4cbef115ea5c4eb?pvs=4"
              className="absolute top-[25%] right-0 text-emerald-600 text-[80px] mr-16"
            >
              <SiNotion />
            </a>
            <a
              href="mailto:alex.yan@nyu.edu"
              className="absolute top-[65%] right-0 text-emerald-600 text-[80px] mr-16"
            >
              <AiOutlineMail />
            </a>
          </div>
          <div className="relative lg:hidden mx-auto flex flex-col justify-center h-screen">
            <a href="https://alex-yan.notion.site/alexyan-me-Message-Board-1fbbfb62cb014b02a4cbef115ea5c4eb?pvs=4" className="text-emerald-600 text-[80px]">
              <SiNotion />
            </a>
            <h1 className="text-center">Leave a message on my notion page</h1>
            <a href="mailto:alex.yan@nyu.edu" className="text-emerald-600 text-[80px]">
              <AiOutlineMail />
            </a>
            <h1 className="text-center">Or just send me an email</h1>
          </div>

        </motion.div>
      </div>
    </div>
  );
};
export default Contact;

import React from 'react';
import Logo from '../assets/logo.svg';

const Header = () => {
  return <header className='py-8'>
    <div className='container mx-auto flex justify-center'>
      <div className='items-center'>
          <img src={Logo} alt='logo'/>
        {/* <button className='bg-white/20 px-4 py-2 rounded-full text-white/50 text-sm ml-4'>Hire Me</button> */}
      </div>
    </div>
  </header>
};

export default Header;
